import { LocaleString } from '@og-shared/types';

import {
  setFeatureFlags,
  setStoredPartnerId,
  uiState,
} from '../store/ui.store';
import { firebaseGetCustomPartnerData } from './functions';
import { extendTranslations } from './translate';
import { StorageKeys, setStorageKey } from './storage';

export const getAndSetPartnerData = async (partnerId?: string | null) => {
  if (!partnerId) return;
  const locale = uiState.locale;
  // console.log('get and set partner', {
  //   stored: `${uiState.stored_partner_id}`,
  //   partnerId,
  // });
  if (uiState.stored_partner_id === partnerId) return;

  return getAndSetPartner({ partnerId, locale });
};

async function getAndSetPartner(params: {
  partnerId: string | null | undefined;
  locale: LocaleString;
}) {
  const { partnerId, locale } = params;
  if (!partnerId) return;
  await setStorageKey(StorageKeys.og_partner_id, partnerId);
  const partnerData = await firebaseGetCustomPartnerData({
    partner_id: partnerId,
    locale,
  });
  if (!partnerData) return;
  const { custom_css, features, translations } = partnerData;
  injectCustomCSS(custom_css);
  setFeatureFlags(features);
  await extendTranslations(translations);
  setStoredPartnerId(partnerId);
  return partnerData;
}

function injectCustomCSS(customCSS?: string) {
  if (!customCSS) return;
  const customStyleEl = document.createElement('style');
  customStyleEl.id = 'og-partner-styles';
  customStyleEl.innerHTML = customCSS;
  document.head.append(customStyleEl);
}
