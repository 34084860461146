import {
  collection,
  doc,
  getFirestore,
  serverTimestamp,
  setDoc,
} from 'firebase/firestore';

import { AnalyticsEvent } from '../analytics';
import { authState } from '../../store/auth.store';
import { groupState } from '../../store/group.store';

export async function firestoreLogAnalyticsEvent(
  eventName: AnalyticsEvent,
  metadata: any = {}
) {
  const uid = authState.userInfo.uid;
  if (!uid) return;
  const group_id = groupState.groupDoc.group_id ?? null;
  const newDocRef = doc(collection(getFirestore(), 'analytics-events'));
  const eventDoc = {
    uid,
    eventName,
    metadata,
    event_id: newDocRef.id,
    group_id,
    timestamp: serverTimestamp(),
  };
  const path = `analytics-events/${eventDoc.event_id}`;
  const docRef = doc(getFirestore(), path);
  return setDoc(docRef, eventDoc, { merge: true });
}
